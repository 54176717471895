// ----- omnibar material search overwrites
mat-form-field.ob, mat-form-field.search, form mat-form-field {
  input { @apply indent-2.5 }
  input::placeholder { @apply absolute pt-1 indent-2.5 sm:indent-0 z-50 }
  .mat-mdc-form-field-flex {
    @apply bg-white w-full;
    .mdc-notched-outline {
      .mdc-notched-outline__notch { @apply w-80 }
      .mdc-notched-outline__trailing { @apply bg-white rounded-t-none rounded-r-3xl rounded-b-3xl rounded-l-none sm:rounded-b-xl sm:rounded-r-xl sm:rounded-bl-none }
      .mdc-notched-outline__leading { @apply bg-white rounded-t-3xl rounded-r-none rounded-b-none rounded-l-3xl sm:rounded-l-xl sm:rounded-t-xl sm:rounded-tr-none; width: 25px !important }
    }
  }
}



.mat-mdc-dialog-container {
  .mdc-dialog__title {
    background-color: var(--color-slate-gray-primary) !important;
    @apply text-white text-sm lg:text-lg flex items-center pl-6 pt-1 lg:pt-2 pb-1 lg:pb-2 #{!important};
  }
  .mdc-dialog__content {
    padding: 40px;
    @apply text-xs lg:text-sm xl:text-lg #{!important};
  }

  .mat-mdc-text-field-wrapper {
    @apply text-xs lg:text-sm xl:text-lg #{!important};
  }
  
  .mdc-button__label {
    @apply text-xs xl:text-sm 2xl:text-base #{!important};
  }
}



.mat-mdc-option .mdc-list-item__primary-text {
  @apply text-sm md:text-sm lg:text-sm 2xl:text-[1em] #{!important};
}

.mat-mdc-option {
  @apply min-h-[28px] md:min-h-[38px] xl:min-h-[48px] #{!important};
}

.mat-nav-list {
  @apply w-0 xl:w-[100%] #{!important};
}

.mat-drawer {
  background-color: var(--color-slate-gray-primary) !important;
}

.mat-mdc-table {
  background: transparent;
}

.mat-mdc-paginator {
  background: transparent;
}

.mat-mdc-form-field-infix {
  width: 100%;
}

form mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch {
  @apply w-[15rem] xs:w-[15rem] sm:w-[15rem];
}

gema3g-omnibar-search .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mdc-list-item__primary-text {
  color: var(--color-black-primary);
}

.mat-toolbar, .mdc-button {
  @apply text-sm;
  /* font-size: 20px; */
  // font-weight: 500;
  line-height: 32px;
  font-family: 'Open Sans Reg', sans-serif !important;
  letter-spacing: 0em;
  margin: 0;
}

items-center {
  align-items: center;
  margin-bottom: 15px;
}

.mdc-list {
  padding: 0px !important;
  line-height: 39px !important;
  .menu-item {
    height: 0px !important;
  }
}

.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  @apply text-xs md:text-sm #{!important};
}

// .cdk-overlay-connected-position-bounding-box {
//   @apply left-[0px] w-[100%] md:w-auto md:left-[30px] #{!important};
// }

.mdc-list-item {
  overflow: visible !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  @media screen and (min-width: $tabletMax) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  @media screen and (min-width: $desktopMin) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  @media screen and (min-width: $desktopMax) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.md-drppicker.double {
  @apply ml-5 lg:absolute lg:right-[0px] sm:w-[370px] #{!important};
}

.mdc-dialog__content {
  overflow: hidden !important;
}

gema3g-my-account .mat-drawer-content {
  overflow: hidden !important;
}

// .mdc-dialog__content, .mat-drawer-content {
//   overflow: hidden !important;
// }

.mat-mdc-menu-item .mat-icon-no-color {
  color: var(--color-white-primary);
}

.mat-toolbar-row {
  height: 40px;
}

.left-link, .right-link {
  color: white !important;
}

.mat-toolbar-multiple-rows {
  min-height: 0px;
}

.mdc-text-field--outlined .mdc-notched-outline {
  z-index:0 !important;
}

mat-form-field .input-field {
  width: 280px !important;
}

gema3g-omnibar-search .get-dense-3 .mat-mdc-raised-button.mat-mdc-button-base {
  background-color: var(--color-dark-primary);
  color: var(--color-white-primary);
}

.get-dense-3 .mat-mdc-raised-button.mat-mdc-button-base {
  @apply text-xs tracking-tight min-w-[107px];
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control, .mat-mdc-select, .mat-mdc-text-field-wrapper, mat-button-toggle-group {
  @apply xs:text-sm 2xl:text-base;
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4 {
  font-family: 'Open Sans Bold', sans-serif !important;
}

.mat-expansion-panel-header-title {
  flex-basis: auto !important;
}

.mat-typography, .mat-expansion-panel-content  {
  font-family: 'Open Sans Reg', sans-serif !important;
}

.mat-expansion-panel-header {
  padding: 0 24px;
}

.mat-content {
  display: block;
}

.mat-expansion-panel-body {
  // padding: 0 20px 46px !important;
  @apply pb-[46px] md:py-[20px]
}

.mat-typography h1 { @apply text-2xl lg:text-3xl}

.mat-typography h2 {
  @apply my-8 sm:my-6;
  line-height: inherit;
  // margin-bottom: 10px;
}

.mat-typography h4 {
  margin-bottom: 0px !important;
}

gema3g-generate-pdf-report .mat-mdc-dialog-content {
  max-height: 90vh !important;
  overflow-y: visible !important;
}

:root {
  --mdc-theme-primary: var(--color-dark-primary) !important;
}

.mat-mdc-slider {
  --mdc-slider-handle-color: var(--color-dark-primary) !important;
  --mdc-slider-focus-handle-color: var(--color-dark-primary) !important;
  --mdc-slider-hover-handle-color: var(--color-dark-primary) !important;
  --mdc-slider-active-track-color: var(--color-dark-primary) !important;
  --mdc-slider-inactive-track-color: var(--color-dark-primary) !important;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-dark-primary) !important;
  --mat-mdc-slider-ripple-color: var(--color-dark-primary) !important;
  --mat-mdc-slider-hover-ripple-color: rgba(76, 133, 21, 0.05) !important;
  --mat-mdc-slider-focus-ripple-color: rgba(76, 133, 21, 0.2) !important;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--color-dark-primary) !important;
  --mdc-switch-selected-handle-color: var(--color-dark-primary) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--color-dark-primary) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--color-dark-primary) !important;
  --mdc-switch-selected-focus-handle-color: #3b5a14 !important;
  --mdc-switch-selected-hover-handle-color: #3b5a14 !important;
  --mdc-switch-selected-pressed-handle-color: #3b5a14 !important;
  --mdc-switch-selected-focus-track-color: var(--color-teranet-green-primary) !important;
  --mdc-switch-selected-hover-track-color: var(--color-teranet-green-primary) !important;
  --mdc-switch-selected-pressed-track-color: var(--color-teranet-green-primary) !important;
  --mdc-switch-selected-track-color: var(--color-teranet-green-primary) !important;
}

.mat-mdc-radio-button, .mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-selected-focus-icon-color: var(--color-dark-primary) !important;
  --mdc-radio-selected-hover-icon-color: var(--color-dark-primary) !important;
  --mdc-radio-selected-icon-color: var(--color-dark-primary) !important;
  --mdc-radio-selected-pressed-icon-color: var(--color-dark-primary) !important;
  --mat-mdc-radio-checked-ripple-color: var(--color-dark-primary) !important;
}

.mat-mdc-checkbox, .mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-focus-icon-color: var(--color-dark-primary) !important;
  --mdc-checkbox-selected-hover-icon-color: var(--color-dark-primary) !important;
  --mdc-checkbox-selected-icon-color: var(--color-dark-primary) !important;
  --mdc-checkbox-selected-pressed-icon-color: var(--color-dark-primary) !important;
}

.mat-mdc-raised-button:not(:disabled) {
  --mdc-protected-button-container-color: var(--color-dark-primary);
}

.mat-button-toggle-checked {
  background-color: var(--color-smalt-secondary);
  color: white !important;
}

gema3g-property-report-demographics .mat-button-toggle-disabled.mat-button-toggle-checked {
  @apply bg-[var(--color-smalt-secondary)] #{!important};
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  @apply text-[var(--color-dark-primary)] #{!important};
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  @apply text-[var(--color-dark-primary)] #{!important};
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  @apply text-[var(--color-dark-primary)] #{!important};
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected~.mdc-checkbox__ripple {
  @apply bg-[var(--color-dark-primary)] #{!important};
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(76, 133, 21, 0.1) !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
  background: var(--color-dark-primary) !important;
}

.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open {
  @apply min-w-min;
}

.ob-search-comparables-split-dropdown-button .mat-button-toggle-label-content {
  @apply p-0 #{!important};
}

.mat-mdc-autocomplete-panel .mat-mdc-option {
  @apply bg-white #{!important};

  .mat-pseudo-checkbox {
    @apply hidden;
  }
  .mdc-list-item__primary-text {
    @apply text-black #{!important};
  }
} 

// TODO: target this with specific class or ID
// @media screen and (min-width: $tabletMax) {
//   .mat-mdc-menu-panel.mat-mdc-menu-panel {
//     display: none !important;
//   }
// }